<template>
	<div class="wmc-footer">
		<div class="wmc-footer-cont">
			<div v-for="(cont, index) in footList" :key="index">
				<div class="wmc-footer-cont-tit">{{ cont.title }}</div>
				<ul class="wmc-footer-cont-list">
					<li v-for="(c, i) in cont.item" :key="i">
						<a href="javascript:void(0);">{{ c.tit }}</a>
					</li>
				</ul>
				<div class="wmc-footer-dashed"></div>
			</div>
			<!-- <div class="wmc-footer-cont-tit">主办单位</div>
      <ul class="wmc-footer-cont-list">
        <li><a href="javascript:void(0);">工业和信息化部</a></li>
        <li><a href="javascript:void(0);">科技部</a></li>
        <li><a href="javascript:void(0);">商务部</a></li>
        <li><a href="javascript:void(0);">国务院国资委</a></li>
        <li><a href="javascript:void(0);">中国工程院</a></li>
        <li><a href="javascript:void(0);">国务院发展研究中心</a></li>
        <li><a href="javascript:void(0);">全国工商联</a></li>
        <li><a href="javascript:void(0);">全国对外友协</a></li>
        <li><a href="javascript:void(0);">中国中小企业协会</a></li>
        <li><a href="javascript:void(0);">全球中小企业联盟</a></li>
        <li><a href="javascript:void(0);">安徽省人民政府</a></li>
      </ul>
      <div class="wmc-footer-cont-tit">支持单位</div>
      <ul class="wmc-footer-cont-list">
        <li><a href="javascript:void(0);">中国侨联</a></li>
        <li>
          <a href="javascript:void(0);">欧美同学会（中国留学人员联谊会）</a>
        </li>
        <li><a href="javascript:void(0);">中国机械工业集团有限公司</a></li>
        <li><a href="javascript:void(0);">中国电子科技集团有限公司</a></li>
        <li><a href="javascript:void(0);">中国建材集团有限公司</a></li>
        <li><a href="javascript:void(0);">中国电子信息产业发展研究院</a></li>
        <li><a href="javascript:void(0);">德国海外商会联盟大中华区</a></li>
      </ul>
      <div class="wmc-footer-cont-tit">承办单位</div>
      <ul class="wmc-footer-cont-list">
        <li><a href="javascript:void(0);">安徽省经济和信息化厅</a></li>
        <li><a href="javascript:void(0);">安徽省商务厅</a></li>
        <li>
          <a href="javascript:void(0);">安徽省人民政府外事办公室（港澳办）</a>
        </li>
        <li><a href="javascript:void(0);">合肥市人民政府等</a></li>
      </ul> -->
			<!-- <div class="wmc-footer-line"></div> -->
			<div class="wmc-footer-copyright">
				<span><a href="https://beian.miit.gov.cn/#/Integrated/index" target="blank">皖ICP备19006570号-4</a></span>
			</div>
			<!-- <div class="wmc-footer-chat-btns">
        <div v-for="(item, index) in barList" :key="index" class="wmc-fcb-item">
          <div class="wmc-hover wmc-footer-ewm-icon">s
            <img :src="item.iconSrc" :alt="item.text + '图标'" />
          </div>
          <div
            class="wmc-tt-ewm wmc-footer-ewm"
            :class="[index == barList.length - 1 ? 'right28' : '']"
          >
            <img :src="item.imgSrc" :alt="item.text + '二维码'" />
          </div>
        </div>
      </div> -->
		</div>
	</div>
</template>
<script>
	import global from "../global/global.js";
	import Axios from "axios";
	export default {
		name: "WmcFooter",
		data() {
			return {
				barList: [{
						iconSrc: require("../assets/images/icon-wx.png"),
						imgSrc: require("../assets/images/wmc-wx.jpeg"),
						text: "微信",
					},
					{
						iconSrc: require("../assets/images/icon-wb.png"),
						imgSrc: require("../assets/images/wmc-wb.png"),
						text: "微博",
					},
					{
						iconSrc: require("../assets/images/icon-tt.png"),
						imgSrc: require("../assets/images/wmc-tt.jpg"),
						text: "头条",
					},
					{
						iconSrc: require("../assets/images/icon-dy.png"),
						imgSrc: require("../assets/images/wmc-dy.jpg"),
						text: "抖音",
					},
					{
						iconSrc: require("../assets/images/icon-bjh.png"),
						imgSrc: require("../assets/images/wmc-bjh.png"),
						text: "百家号",
					},
				],
				footList1: [],
				footList2: [],
				footList3: [],
			};
		},
		computed: {
			lang() {
				return this.$store.state.lang;
			},
			langIfZh() {
				return this.$store.state.lang == "zh-CN";
			},
			footList() {
				return [{
						title: this.$t("language.sponsor"),
						item: this.footList1.length > 0 ?
							this.footList1 : [{
									path: "",
									tit: this.$t("language.ahsrmzf")
								},
								{
									path: "",
									tit: this.$t("language.gjzzqgjszlwyh")
								},
								{
									path: "",
									tit: this.$t("language.zgzxqyxh")
								},
								{
									path: "",
									tit: this.$t("language.qqzxqylm")
								}
							],
					},
					{
						title: this.$t("language.supportingAgency"),
						item: this.footList2.length > 0 ?
							this.footList2 : [{
									path: "",
									tit: this.$t("language.zgql")
								},
								{
									path: "",
									tit: this.$t("language.zgjxgyjt")
								},
								{
									path: "",
									tit: this.$t("language.zgqylhh")
								},
								{
									path: "",
									tit: this.$t("language.zgjxgylhh")
								},
								{
									path: "",
									tit: this.$t("language.zggfhyxh")
								},
								{
									path: "",
									tit: this.$t("language.zgdzxxcyfayjy")
								},
								{
									path: "",
									tit: this.$t("language.zggyhlwyjy")
								},
							],
					},
					{
						title: this.$t("language.organizer"),
						item: this.footList3.length > 0 ?
							this.footList3 : [{
									path: "",
									tit: this.$t("language.sgyhxxht")
								},
								{
									path: "",
									tit: this.$t("language.ansswt")
								},
								{
									path: "",
									tit: this.$t("language.swbswt")
								},
								{
									path: "",
									tit: this.$t("language.sgzw")
								},
								{
									path: "",
									tit: this.$t("language.smch")
								},
								{
									path: "",
									tit: this.$t("language.hfsrmzf")
								},
							],
					},
				];
			},
		},
		watch: {
			lang(val) {
				if (val == "zh-CN") {
					this.languageVersion = 1;
				} else {
					this.languageVersion = 2;
				}

				this.init(0); //主办单位
				this.init(1); //支持单位
				this.init(2); //承办单位
				this.key += 1;


			},
		},
		mounted() {

			if (this.$store.state.lang == "zh-CN") {
				this.languageVersion = 1;
			} else {
				this.languageVersion = 2;
			}
			this.init(0); //主办单位
			this.init(1); //支持单位
			this.init(2); //承办单位
		},
		methods: {
			init(type) {
				console.log("======" + this.$store.state.lang)
				Axios.get(global.apiUrl + "/wmc-server/api/index/unit", {
					params: {
						type: type,
						year: "2024",
						language: (this.languageVersion - 1)
					},
				}).then((response) => {
					if (response.data.code === 200) {
						let arr = response.data.data.list.map((item) => ({
							path: item.url,
							tit: item.title,
						}));
						if (type === 0) {
							this.footList1 = arr;
						} else if (type === 1) {
							this.footList2 = arr;
						} else if (type === 2) {
							this.footList3 = arr;
						} else {
							return;
						}
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.wmc-footer {
		padding: 20px 0;
		background: #fff;
	}

	.wmc-footer-cont {
		width: 1200px;
		margin: 0 auto;
		position: relative;
	}

	.wmc-footer-cont-tit {
		font-size: 18px;
		font-weight: bold;
		color: #000000;
		line-height: 40px;
	}

	.wmc-footer-cont-list li {
		display: inline-block;
		margin-right: 50px;
		line-height: 30px;
	}

	.wmc-footer-copyright {
		text-align: center;
	}

	.wmc-footer-cont-list li a,
	.wmc-footer-copyright span a {
		font-size: 16px;
		color: rgba(0, 0, 0, 0.65);
	}

	.wmc-footer-cont-list li a:hover,
	.wmc-footer-copyright span a:hover {
		opacity: 0.7;
	}

	.wmc-footer-cont-list li a:active,
	.wmc-footer-copyright span a:active {
		opacity: 1;
	}

	.wmc-footer-line {
		height: 1px;
		background: #41424d;
		margin: 16px 0;
	}

	.wmc-footer-dashed {
		margin: 12px 0;
		border-bottom: 1px dashed #707070;
	}

	.wmc-footer-copyright span {
		// margin-left: 45%;
		align-content: center;
		display: inline-block;
		// margin-right: 24px;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.7);
		line-height: 34px;
		cursor: pointer;
	}

	.wmc-footer-chat-btns {
		position: absolute;
		bottom: 0;
		right: 0;
		/* width: 200px; */
		height: 30px;
		z-index: 100;
	}

	.wmc-fcb-item {
		text-align: center;
		position: relative;
		width: 35px;
		height: 28px;
		display: inline-block;
		cursor: pointer;
		margin-right: 40px;

		.wmc-footer-ewm-icon {
			img {
				vertical-align: middle;
				width: 100%;
			}
		}

		.wmc-footer-ewm {
			position: absolute;
			width: 136px !important;
			right: -46px;
			top: -160px;
			background-color: #fff;
			display: none;
			transition: all 0.2s;
			-webkit-transition: all 0.2s;
			-moz-transition: all 0.2s;
			-ms-transition: all 0.2s;
			-o-transition: all 0.2s;
			z-index: -1;

			img {
				width: 100%;
				height: 100%;
			}
		}

		&:hover {
			.wmc-footer-ewm {
				display: block !important;
			}
		}
	}

	.right28 {
		right: -20px !important;
	}
</style>