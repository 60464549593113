<template>
	<div class="wmc-container">

		<el-scrollbar style="height: 100%" ref="scrollRef">


			<nav-bar></nav-bar>
			<div class="app-main">
				<!-- <keep-alive> -->
				<router-view />
				<!-- </keep-alive> -->
			</div>
			<!-- 底部 -->
			<wmc-footer id="wmc-footer" v-if="needFooter" :style="{
          paddingBottom: showScrollNews && currPath == '/index' ? '0' : '0',
        }"></wmc-footer>
			<!-- 侧边工具栏 -->
			<side-bar v-if="showSideBar" @goTop="goTop"></side-bar>

			<div v-if="showLeftBotton" class="fixed-div">
				<a href="https://www.dhsgw.com/homepage" target="_blank">
					<img src="../../assets/images/left_bottom.png" style="width:15vw" alt="" />
				</a>


			</div>

			<mobile-menu v-if="showMobileMenu"></mobile-menu>
		</el-scrollbar>
	</div>
</template>
<script>
	// 头部导航
	import NavBar from "@/components/NavBar/index";
	// 底部组件
	import WmcFooter from "@/components/WmcFooter";
	// 侧边工具栏
	import SideBar from "@/components/SideBar";
	// 滚动新闻
	// import WmcScrollNews from "@/components/WmcScrollNews";
	// 移动端导航
	import MobileMenu from "@/components/MobileMenu";
	export default {
		name: "wmc-layout",
		components: {
			NavBar,
			WmcFooter,
			SideBar,
			MobileMenu
		},
		data() {
			return {
				showSideBar: false,
				showScrollNews: true,
				showLeftBotton: false
			};
		},
		mounted() {
			window.sessionStorage.setItem(
				"scrollBar",
				this.$refs.scrollRef.wrap.scrollTop
			);
			this.$refs.scrollRef.wrap.addEventListener("scroll", this.scroll, true);
			// this.scroll();
			setTimeout(() => {
				this.scroll();
			}, 1000);


			// let img = document.getElementById("imgbanner");
			// var height = img.clientHeight;
			// document.getElementById("wmc-footer").style.marginBottom = (10 + height) + "px";
		},
		computed: {
			needFooter() {
				return this.$route.meta.withOutFooter ? false : true;
			},
			currPath() {
				return this.$route.path;
			},
			showMobileMenu() {
				return this.$store.state.showMobileMenu;
			},
			langIfZh() {
				return this.$store.state.lang == "zh-CN";
			},
		},
		methods: {
			scroll() {
				var scrollTop = this.$refs.scrollRef.wrap.scrollTop;
				var distance = 10;
				var sideBarDistance = 200;
				// var distance =
				//   document.getElementsByClassName("wmc-banner")[0].clientHeight;
				// 导航栏
				if (scrollTop >= distance) this.$store.commit("SET_WHITE_HEADER", true);
				else this.$store.commit("SET_WHITE_HEADER", false);
				// 侧边工具栏
				if (scrollTop >= sideBarDistance) this.showSideBar = true;
				else this.showSideBar = false;

				let clientHeight = document.documentElement.clientHeight; // 视口高度
				let clientWidth = document.documentElement.clientWidth; // 视口高度
				let domList = document.getElementsByClassName("wmc-animate");
				Array.prototype.slice.call(domList).forEach((item) => {
					let top = this.getPositionTop(item);
					if (top < clientHeight + scrollTop - 100) {
						item.classList.add("animate-to-top");
					}
				});

				if (clientHeight > clientWidth) {
					this.showLeftBotton = false;
				} else {
					this.showLeftBotton = true;
				}
			},
			getPositionTop(node) {
				// 获取元素到顶部距离-通用方法
				var top = node.offsetTop;
				var parent = node.offsetParent;
				while (parent != null) {
					top += parent.offsetTop;
					parent = parent.offsetParent;
				}
				return top; // 所有的父元素top和
			},
			// 返回顶部
			goTop() {
				const timeTop = setInterval(() => {
					this.$refs.scrollRef.wrap.scrollTop -= 50;
					if (this.$refs.scrollRef.wrap.scrollTop <= 0) {
						clearInterval(timeTop);
					}
				}, 10);
			},
			// 关闭滚动新闻
			handleShowScrollNews() {
				this.showScrollNews = false;
			},
		},
	};
</script>

<style>
	.wmc-container {
		height: 100%;
		width: 100%;
		overflow: auto;
	}

	.fixed-div {
		position: fixed;
		bottom: 20px;
		/* 距离底部20px */
		left: 20px;
		/* 背景颜色，半透明 */
		color: white;
		z-index: 9999;
		/* 确保 div 位于最上层 */
	}
</style>